<template>
	<div id="body">
		<div class="fatherBox">
			<div class="headerBox">
				<!-- <span class="stutitle">学生管理</span> -->
				<p>学生列表</p>
				<el-button style="margin-left: auto;" type="primary" @click="moban">模板下载</el-button>
				<el-button type="primary" @click="exportZip">导出数据包</el-button>
				<el-button type="primary" @click="dialogExcelstu = true">批量导入</el-button>
				<el-button type="primary" @click="excelChu">导出学生</el-button>
				<el-button type="primary" @click="applylist()">申请列表
					<span v-if="studentApplyNum > 0 "
						style="   display: inline-block; background: red; padding: 0 4px;margin: 0 0 0 6px;border-radius: 10px;color: white;">{{studentApplyNum}}</span>
				</el-button>
				<el-button type="primary" @click="guaapplylist()">家长申请
					<span v-if="parentApplyNum > 0 "
						style="   display: inline-block; background: red; padding: 0 4px;margin: 0 0 0 6px;border-radius: 10px;color: white;">{{parentApplyNum}}</span>

				</el-button>
				<el-button type="primary" @click="addStunent">+创建学生</el-button>
			</div>
			<div class="query">
				<div class="queryLeft">
					<div style="float: left ;width: fit-content;margin-right: 10px;"
						v-if=" Status == 'school' ? true :false">
						年级分类:
						<el-select v-model="gradeid" @change="classSelect()" clearable style="width: 150px;">
							<el-option v-for="(item,index) in gradeList" :key="index" :label="item.TypeName"
								:value="item.id">
							</el-option>
						</el-select>
					</div>&nbsp;&nbsp;
					<div style="float: left;width: fit-content;margin-right: 10px;"
						v-if=" Status == 'school' ? true :false">
						班级分类:
						<el-select v-model="classid" clearable style="width: 150px;">
							<el-option v-for="(item,index) in classList" :key="index" :label="item.ClassName"
								:value="item.id">
							</el-option>
						</el-select>
					</div>&nbsp;&nbsp;
					<div style="float: left;width: fit-content;margin-right: 10px;"
						v-if=" Status == 'school' ? true :Status == 'teacher' ? true : false">
						卡号:
						<el-input class="select" v-model.trim="stuname" placeholder="清输入学生卡号" style="width: 160px;">
						</el-input>
					</div>
					<div style="float: left;width: fit-content;margin-right: 10px;"
						v-if=" Status == 'school' ? true :Status == 'teacher' ? true : false">
						学号:
						<el-input class="select" v-model.trim="stu_id" placeholder="清输入学生学号" style="width: 160px;">
						</el-input>
					</div>
					<div style="float: left;width: fit-content;margin-right: 10px;"
						v-if=" Status == 'school' ? true :Status == 'teacher' ? true : false">
						长编号:
						<el-input class="select" v-model.trim="longnum" placeholder="清输入学生长编号" style="width: 160px;">
						</el-input>
					</div>
				</div>
				<div class="queryRight" v-if=" Status == 'school' ? true :Status == 'teacher' ? true : false">
					<el-button @click="reset()">重置</el-button>
					<el-button type="primary" @click="studentSearchfirst(1)">查询</el-button>
				</div>
			</div>
			<!--学校端 -->
			<el-table :data="ClassInfos" v-loading="loading" style="margin-top: 31px;" v-if=" Status == 'school'">
				<el-table-column type="index" label="序号" width="auto" align="center">
					<template slot-scope="scope">
						{{ (currpage -1) * pagesize + scope.$index + 1 }}
					</template>
				</el-table-column>
				<el-table-column label="班级" prop="StuName" width="auto" align="center">
					<template slot-scope="scope">{{scope.row.TypeName+scope.row.ClassName}}</template>
				</el-table-column>
				<el-table-column label="学生姓名" prop="StuName" width="auto" align="center"></el-table-column>
				<el-table-column prop="StuGender" label="性别" width="auto" align="center" :formatter="Gender">
				</el-table-column>
				<el-table-column prop="kid" label="卡号" width="auto" align="center"> </el-table-column>
				<el-table-column prop="stu_id" label="学号" width="auto" align="center"> </el-table-column>
				<el-table-column prop="short_num" label="短编号" width="auto" align="center"> </el-table-column>
				<el-table-column prop="StuNumber" label="学生编号" width="auto" align="center"> </el-table-column>
				<el-table-column prop="" label="家长认证" align="center" width="auto">
					<template slot-scope="scope">
						<el-link v-if="scope.row.parentNum != '0'" type="primary"
							@click="goPatriarchDetail(scope.row.StuUserID)">查看
						</el-link>
						<span v-else>无</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="图像认证" align="center" width="auto">
					<template slot-scope="scope">
						<el-image v-if="scope.row.ImageUrl" alt="认证照片" :src="scope.row.ImageUrl"
							:preview-src-list="[scope.row.ImageUrl]" fit="contain"
							style="width: 100px; height: 100px"></el-image>
						<span v-else>未认证</span>
					</template>
				</el-table-column>

				<el-table-column label="操作" fixed="right" align="center" width="200">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" plain
							@click="handleMove(scope.index, scope.row)">移除学生</el-button>
						<el-button size="mini" type="primary" plain @click="StuDetail(scope.$index, scope.row)"
							style="background: #409eff;color: #fff;">详情</el-button>
					</template>
				</el-table-column>
			</el-table>

			<!--教师端 -->
			<el-table :data="ClassInfos" v-loading="loading" style="margin-top: 31px;" v-if=" Status == 'teacher'">
				<el-table-column type="index" label="序号" width="auto" align="center">
					<template slot-scope="scope">
						{{ (currpage -1) * pagesize + scope.$index + 1 }}
					</template>
				</el-table-column>
				<el-table-column label="班级" prop="StuName" width="auto" align="center">
					<template slot-scope="scope">{{scope.row.TypeName+scope.row.ClassName}}</template>
				</el-table-column>
				<el-table-column label="学生姓名" prop="StuName" width="auto" align="center"></el-table-column>
				<el-table-column prop="StuGender" label="性别" width="auto" align="center" :formatter="Gender">
				</el-table-column>
				<el-table-column prop="stu_id" label="学号" width="auto" align="center"> </el-table-column>
				<el-table-column prop="short_num" label="短编号" width="auto" align="center"> </el-table-column>
				<el-table-column prop="StuNumber" label="学生编号" width="auto" align="center"> </el-table-column>
				<el-table-column prop="" label="家长认证" align="center" width="auto">
					<template slot-scope="scope">
						<el-link v-if="scope.row.guaNum != '0'" type="primary"
							@click="goPatriarchDetail(scope.row.StuUserID)">查看
						</el-link>
						<span v-else>未认证</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="图像认证" align="center" width="auto">
					<template slot-scope="scope">
						<el-image v-if="scope.row.ImageUrl" alt="认证照片" :src="scope.row.ImageUrl"
							:preview-src-list="[scope.row.ImageUrl]" fit="contain"
							style="width: 100px; height: 100px"></el-image>
						<span v-else>未认证</span>
					</template>
				</el-table-column>

				<el-table-column label="操作" fixed="right" align="center" width="200">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" plain
							@click="handleMove(scope.index, scope.row)">移除学生</el-button>
						<el-button size="mini" type="primary" plain @click="StuDetail(scope.$index, scope.row)"
							style="background: #409eff;color: #fff;">详情</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div v-show="CountNum > 0">
				<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
					layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum"
					style="margin-top:20px">
				</el-pagination>
			</div>
			<el-dialog title="批量导入" :visible.sync="dialogExcelstu" top="50px" :close-on-click-modal="false" width="18%"
				style="padding: 30px 20px 20px 20px;">

				<el-form :model="form" ref="form" label-position="right" label-width="70px" style="text-align: left;">
					<el-form-item label="">
						<!-- 	<el-button type="small" style="float: left;margin-top: 5px;margin-left: 50px;" @click="moban">
							模板下载</el-button> -->
						<el-upload ref="upload" class="file" :action="url" :file-list="file" :auto-upload="false"
							:on-success="change" :limit="1" :headers="{'token':token}" :data="form">
							<el-button slot="trigger" size="small" type="primary">选取文件</el-button>
						</el-upload>
					</el-form-item>
					<el-form-item style="margin-top: 10px;">
						<el-button type="primary" @click="onSubmitee" style="padding: 10px 14px;">提交</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog :visible.sync="studentDialog" width="31vw">
				<template slot="title">
					<div class='' style="font-size: 26px;">新建学生</div>
				</template>
				<table style="margin-top: -20px;" class="addta">
					<tr>
						<td class="tit">姓名：</td>
						<td>
							<el-input v-model="StuName" placeholder="请输入学生姓名" style="width: 20vw;"></el-input>
						</td>
					</tr>
					<tr v-if=" Status == 'school' ? true :false">

						<td class="tit">班级：</td>
						<td>
							<el-select v-model="grade" @change="classSelect1()" clearable
								style="width: 190px;margin-right: 3px;">
								<el-option v-for="(item,index) in gradeList" :key="index" :label="item.TypeName"
									:value="item.id">
								</el-option>
							</el-select>
							<el-select v-model="upclassid" clearable style="width: 190px;">
								<el-option v-for="(item,index) in classList1" :key="index" :label="item.ClassName"
									:value="item.id">
								</el-option>
							</el-select>
						</td>
					</tr>
					<tr v-if="userInfo.onlyStuNum!=2">
						<td class="tit">学号：</td>
						<td>
							<el-input v-model="stu_id" placeholder="请输入学号" style="width: 20vw;"></el-input>
						</td>
					</tr>
					<tr>
						<td class="tit">性别：</td>
						<td style="text-align: left;">
							<el-radio v-model="gender" label="1">男</el-radio>
							<el-radio v-model="gender" label="2">女</el-radio>
						</td>
					</tr>
					<tr>
						<td class="tit">出生年月日：</td>
						<td>
							<el-date-picker type="date" placeholder="选择日期" v-model="birthday"
								:picker-options="pickerOptions" value-format="yyyy-MM-dd"
								style="width: 100%;"></el-date-picker>
							<!-- <el-input v-model="stuname" placeholder="请输入学生姓名" style="width: 20vw;"></el-input> -->
						</td>
					</tr>
					<tr>
						<td class="tit">上传照片：</td>
						<td>
							<el-upload ref="upload" :action="imgurl" list-type="picture-card" :limit="1"
								:on-progress="uploading" :file-list="imgList" accept="image/jpg,image/png,image/jpeg"
								:on-remove="removeImg" :headers="{'token':token}" :on-success="fileSuccess"
								:on-error="imgError" :on-exceed="limitAlert" style="height: 145px;">
								<i slot="default" class="el-icon-plus"></i>
							</el-upload>
						</td>
					</tr>
				</table>
				<p style="width: 100%;">
					<el-button type="primary" @click="addStunentData()"
						style="display: inline-block;margin-right: 40px;">添 加
					</el-button>
					<el-button type="primary" @click="addreset()" style="display: inline-block;">取 消</el-button>
				</p>

				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		classStudentLis,
		classStudentRemove,
		gradeClass,
		addstu,
		guaApplyelist,
		luoeyeExcelchu,
		zipchu
	} from '@/api/TeacherApi.js'
	import {
		newstuapply
	} from '@/api/user.js'

	import {
		mapGetters
	} from "vuex";
	export default {
		computed: {
			...mapGetters(["Status"]),
		},
		data() {
			return {
				stu_id: '', // 查询学号,
				imgData: [], //上传服务器图片数组
				// imgurl: this.$url + 'upload/imgFace', //上传人脸请求地址(本地)
				imgurl: this.$url + '/upload/imgFace', //上传人脸请求地址(线上)
				// imgurl: this.$url + 'upload/img', //上传图片请求地址(本地)
				imgList: [],
				studentDialog: false,
				form: {},
				ClassInfos: [],
				Switch_on: false,
				longnum: '', //长编号
				authGuaNum: '0', //家长认证人数
				authStuNum: '0', //学生认证人数
				loading: false, //加载框
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
				SearchName: '', // 学生姓名查询
				dqpage: '', // 当前页
				dialogExcelstu: false, //excel导入
				// url: this.$url + 'teacher/teastuExcelAdd', //上传文件请求adminApi 本地用这个
				url: this.$url + '/teacher/teastuExcelAdd', //上传文件请求adminApi 线上
				file: [], //上传的file数组
				token: localStorage.getItem('token'), //token
				stuname: '', //学生姓名查询
				SearchName: '', // 学生姓名查询状态
				gradeid: '', //年级查询id
				gradeList: [], //年级数组
				classid: '', //班级查询id
				classList: [], // 班级数组
				classList1: [], // 班级数组
				gradeidsearch: '', // 学生年级查询状态
				classidsearch: '', // 学生班级查询状态

				//上传参数

				grade: '',
				StuName: '',
				stu_id: '',
				upclassid: '',
				gender: '',
				birthday: '',
				stuname: '',
				images: '',
				facefeature: '', //人脸识别码
				//日期参数
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
				},
				studentApplyNum: 0, //学生申请数量
				parentApplyNum: 0, //家长申请数量

                userInfo: JSON.parse(localStorage.getItem('userInfo'))
			}
		},
		mounted() {
			this.StudentList(1) // 获取学生列表
			this.gradeListvue() // 获取年级信息
			this.stuApplyList() //获取学生申请数量
			this.parentApplyList() //获取学生申请数量
			// console.log(this.Status)
			this.$store.state.sApply = 10
			this.$store.state.pApply = 10
		},
		methods: {
			reset() { //重置按钮
				this.classList = [];
				this.gradeid = '';
				this.classid = '';
				this.stuname = '';
				this.longnum = '';
				this.SearchName = '';
				this.gradeidsearch = '';
				this.classidsearch = '';
				this.StudentList(1);
			},
			addStunent() { // 添加学生
				this.studentDialog = true
			},
			//取消并清空
			addreset() {
				this.studentDialog = false
				this.grade = '';
				this.StuName = '';
				this.upclassid = '';
				this.gender = '';
				this.stuname = '';
				this.longnum = '';
				this.birthday = '';
			},
			addStunentData() { //

            console.log(this.userInfo)
            
                // 学号创建学生
                if (this.userInfo.onlyStuNum != 2) {
                    if (this.stu_id == '') {
                        this.$message.error('学号不能为空');
                        return false;
                    }
                } 
                // 非学号创建学生
                else {
                    if (this.StuName == '') {
                        this.$message.error('姓名不能为空');
                        return false;
                    }
                    if (this.gender == '') {
                        this.$message.error('性别不能为空');
                        return false;
                    }
                    if (this.birthday == '') {
                        this.$message.error('生日不能为空');
                        return false;
                    }
                }

				
				if (this.grade == '' && this.Status == 'school') {
					this.$message.error('年级不能为空');
					return false;
				}
				if (this.upclassid == '' && this.Status == 'school') {
					this.$message.error('班级不能为空');
					return false;
				}
				// if (this.images == '') {
				// 	this.$message.error('照片不能为空');
				// 	return false;
				// }
				const data = {
					StuName: this.StuName,
					stu_id: this.stu_id,
					grade: this.grade,
					class: this.upclassid,
					gender: this.gender,
					birthday: this.birthday,
					image: this.images,
					facefeature: this.facefeature
				}

				addstu(data).then(res => {
					if (res.status.code == 1) {
						this.addreset();
						this.$message({
							message: '添加学生成功',
							type: 'success'
						})
						this.StudentList(1) // 获取学生列表
					} else {
						this.$message({
							message: res.status.msg,
							type: 'error'
						})
					}

				})
			},
			handleMove(index, row) { // 移除本班
				this.$confirm('此操作将移除学生, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const data = {
						StuUserID: row.StuUserID, // 学生ID
						StuName: row.StuName, // 学生名字
						TypeName: row.TypeName, //年级
						ClassName: row.ClassName //班级
					}
					// console.log(this.ClassInfos.length)
					// return
					classStudentRemove(data).then(res => {
						if (res.status.code == 1) {
							this.$message({
								message: '移除成功',
								type: 'success'
							})
							if (this.ClassInfos.length == 1) {
								if (this.currpage > 1) {
									this.studentSearch(this.currpage - 1) // 刷新
								} else {
									this.studentSearch(this.currpage) // 刷新
								}
							} else {
								this.studentSearch(this.currpage) // 刷新
							}

						} else {
							this.$message.error(res.status.msg)
						}
					}).catch(err => {
						console.log(err)
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消移除'
					});
				});
			},
			// 导出数据包
			exportZip() {
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				zipchu().then(res => {
					loading.close()
					window.open(res.data.download)
				})
			},
			StudentList(currpage) { // 学生列表
				this.loading = true
				const data = {
					page: currpage, // 当前页数
					limit: this.pagesize, //	每页条数
					type: 0
				}
				classStudentLis(data).then(res => {
					this.ClassInfos = res.data.List;
					this.authGuaNum = res.data.authGuaNum; //家长认证人数
					this.authStuNum = res.data.authStuNum; //学生认证人数
					this.CountNum = parseInt(res.data.CountNum); // 总条数
					this.loading = false;
				}).catch(err => {
					console.log(err)
				})
			},
			Gender(row, column) { // 筛选性别
				return row.StuGender == 1 ? '男' : '女'
			},

			applylist() {
				this.$router.push({ //页面跳转
					name: 'applyList', //往这个页面跳转
					params: { //跳转传递的参数
					}
				})
			},
			guaapplylist() {
				this.$router.push({ //页面跳转
					name: 'guaapplylist', //往这个页面跳转
					params: { //跳转传递的参数
					}
				})
			},

			StuDetail(index, row) {

				this.$router.push({ //页面跳转
					name: 'studetail', //往这个页面跳转
					params: { //跳转传递的参数
						StuID: row.StuUserID, //这个StuID 是router文件下 	index.js path: 'eyesight/:StuID?', 必须要传
						data: row
					}
				})
			},
			goPatriarchDetail(id) { //跳转去家长详情页
				this.$router.push({
					name: 'PatriarchDetail',
					params: {
						studentId: id
					}
				})
			},
			handleCurrentChange(val) {
				//console.log(`当前页: ${val}`);
				if (this.SearchName == "" && this.gradeidsearch == "" && this.classidsearch == "") {
					this.StudentList(val)
					this.currpage = val
				} else {
					this.studentSearch(val) //当有姓名条件时 分页操作
				}
			},
			studentSearchfirst(val) { //只有当点击查询时候走这个方法

				this.SearchName = this.stuname;
				this.gradeidsearch = this.gradeid;
				this.classidsearch = this.classid;
				this.currpage = val;

				const data = {
					StuName: this.SearchName,
					gid: this.gradeidsearch,
					cid: this.classidsearch,
					page: val,
					limit: this.pagesize,
					stu_id: this.stu_id,
					StuNumber: this.longnum
				}

				classStudentLis(data).then(res => {
					this.currpage = 1;
					this.ClassInfos = res.data.List;
					this.CountNum = parseInt(res.data.CountNum);
					this.loading = false;
				}).catch(err => {
					console.log(err)
				})
			},

			studentSearch(val) { //当第一次 点了查询条件后 分页走这个方法
				this.currpage = val
				const data = {
					StuName: this.SearchName,
					gid: this.gradeidsearch,
					cid: this.classidsearch,
					page: this.currpage,
					limit: this.pagesize
				}

				classStudentLis(data).then(res => {
					this.ClassInfos = res.data.List;
					this.CountNum = parseInt(res.data.CountNum);
					this.loading = false;
				}).catch(err => {
					console.log(err)
				})
			},
			async onSubmitee() { //提交按钮
				this.$refs.upload.submit(); //手动上传
			},
			change(res) { //上传文件成功回调
			console.log(res)
				if (res.status.code == 1) {
					this.$message.success('导入成功');
					this.form = {};
					this.file = [];
					this.StudentList(1) // 获取学生列表
				} else {
					this.$message.error(res.status.msg);
					this.file = [];
				}
			},
			moban() { // 模板下载
				window.open('http://sygp.shiyuntech.net/html/tem/导入模板.xls');
			},
			gradeListvue() { // 年级列表
				const data = {
					type: 1 //没用
				}
				gradeClass(data).then(res => {
					this.gradeList = res.data;
				}).catch(err => {
					console.log(err)
				})
			},
			classSelect() { // 班级列表
				this.classid = ''
				this.classList = []

				const data = {
					gid: this.gradeid
				}

				if (this.gradeid != "") {
					gradeClass(data).then(res => {
						this.classList = res.data;
					}).catch(err => {
						console.log(err)
					})
				}

			},
			classSelect1() { // 班级列表
				this.upclassid = ''
				this.classList1 = []
				console.log('当前年级', this.grade)
				const data = {
					gid: this.grade
				}

				if (this.grade != "") {
					gradeClass(data).then(res => {
						this.classList1 = res.data;
					}).catch(err => {
						console.log(err)
					})
				}

			},
			uploading(event, file, fileList) { //判断用户上传文件类型和大小
				if (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/png') {
					const size = file.size / 1024
					if (size > 1024) {
						this.$message.error('图片大小不能超过1M！')
						this.$refs.upload.abort() //取消上传
						this.imgList = fileList.filter(item => item.uid != file.uid)
					} else {}
				} else {
					this.$message.error("只可以上传jpeg、jpg和png格式图片，请重新上传！")
					this.imgList = fileList.filter(item => item.uid != file.uid)
					this.$refs.upload.abort() //取消上传
				}
			},
			limitAlert() { //展示图片超过张数提醒
				this.$message.error('最多能上传1张图片！')
			},
			imgError(err, file, fileList) { //展示图片上传失败提醒
				this.$message.error('图片上传失败！')
			},
			removeImg(file, fileList) { //展示图片删除提醒
				this.$message.success('图片删除成功！')
				this.imgData = this.imgData.filter(item => item.imgId != file.uid) //从imgData 过滤掉 item.imgId 不等于 file.uid
			},
			fileSuccess(response, file, fileList) { //展示图片上传成功

				if (response.data.err == 1) {
					this.$message.error('未检测到人脸！')
					this.imgList = [];
				} else {
					this.$message.success('图片上传成功！')
					this.images = response.data.url;
					this.facefeature = response.data.facefeature;
				}

				// this.imgData.push(data); //把上传成功的返回的地址（一般是oss地址） 赋值给imgData
			},
			stuApplyList() {
				// 学生申请列表数量
				const data = {
					page: 1, //	当前页数
					limit: 1 //	每页条数
				}
				newstuapply(data)
					.then((res) => {
						this.studentApplyNum = parseInt(res.data.CountNum) // 总条数
					})
					.catch((err) => {
						console.log(err)
					})
			},
			parentApplyList() {
				// 家长申请列表数量
				const data = {
					page: 1, //	当前页数
					limit: 1 //	每页条数
				}
				guaApplyelist(data)
					.then((res) => {
						this.parentApplyNum = parseInt(res.data.CountNum) // 总条数
					})
					.catch((err) => {
						console.log(err)
					})
			},
			excelChu() { // 导出学生信息
				const data = {
					id: 1,
					gid: this.gradeidsearch,
					cid: this.classidsearch
				}
				luoeyeExcelchu(data).then(res => {

					if (res.status.code == 1) {
						//window.open('http://localhost:8788/' + res.data);//本地
						//window.open('http://shiyun.dljianwei.com' + res.data);//测试
						//window.open('http://sygp.shiyuntech.net' + res.data); //线上
						window.open(this.$url + res.data);
					} else {
						this.$message.error(res.status.msg)
					}

				}).catch(err => {
					console.log(err)
				})
			},

		}

	}
</script>

<style lang="scss" scoped>
	#body {
		// height: 100%;
		background: #f0f2f5;
	}

	.fatherBox {
		width: 98%;
		// height: 800px;
		display: flex;
		background: white;
		margin: 0 auto;
		flex-direction: column;
		justify-content: center;

		.headerBox {
			display: flex;
			height: 52px;
			width: 98%;
			align-items: center;
			justify-content: space-between;
			border-bottom: 2px solid #e9e9e9;

			p {
				font-size: 16px;
				font-family: 萍方-简;
				font-weight: normal;
				color: #000000;
				margin-left: 32px;
			}

			.btn {
				display: flex;
				align-items: center;
				width: 109px;
				height: 32px;
				opacity: 1;
				border-radius: 2px;
			}
		}

		.query {
			width: 98%;
			align-items: center;
			display: flex;
			justify-content: space-between;
			padding-top: 20px;
			padding-left: 32px;

			.queryLeft {
				width: 80%;
				display: flex;

				div {
					margin-right: 20px;
					width: 35%;
					text-align: left;

					.input,
					.select {
						width: 60%;
					}
				}
			}
		}
	}
</style>